import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import jwtDecode from 'jwt-decode';
import rootReducer from './reducers';
import { setCurrentUser, setAccessToken } from './actions/account';

const middleware = [thunk];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

if(localStorage.getItem('accessToken')){
  try {
    const accessToken = localStorage.getItem('accessToken');

    const decodedJwt = jwtDecode(accessToken)
    
    store.dispatch(setAccessToken(accessToken));
    store.dispatch(setCurrentUser(decodedJwt));
  } catch (error) {
    
  }
}

export default store;
