import { SET_CURRENT_USER, UPDATE_USER_INFO } from '@actions/types';

let initialState = {
  isLoggedIn: false,
  user: {}
};

export default function user(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_USER:
      return {
        isLoggedIn: !!(Object.keys(payload).length),
        user: payload
      };
    case UPDATE_USER_INFO: 
      if(state.isLoggedIn){
        return {
          ...state,
          user: {
            ...state.user,
            ...payload
          },
        }
      }else{
        return {
          user: null,
        }
      }
    default:
      return state;
  }
}